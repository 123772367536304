.mainTopMenu__{
    &root {
        position: relative;
        font-size: 1rem;
        line-height: 1.25em;
        display: block;
        width: 100%;
    }
    &moreButton {
        visibility: hidden;
    }
    &visible,
    &hidden {
        list-style: none;
        padding: 0;
        margin: 0;
        & li {
            white-space: nowrap;
            padding: 5px;
        }
    }
    &hidden {
        background-color: white;
        padding: 5px 0;
        position: relative;
        display: none;
        & li {
            padding: 5px 10px;
        }
    }
}

@media (min-width: 768px) {
    .mainTopMenu__{
        &visible {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-end;
            & li{
                border-left:1px solid #b9c9de;
                &:first-child{
                    border-left:0;
                }
            }
        }
        &moreButton {
            flex: 0 0 32px;
            cursor: pointer;
            text-align: center;
            font-weight: bold;
            visibility: visible;
        }
        &hidden {
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
            position: absolute;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s linear 0s, visibility 0s linear 0.2s;
            right: 0;
            border-radius: 5px;
            display: block;
            &.show {
                visibility: visible;
                opacity: 1;
                transition: opacity 0.2s linear 0s, visibility 0s linear 0s;
            }
        }
    }
}
