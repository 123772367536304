.imagesCarousel__ {
    &root {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        & img {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    &visibilityToggle {
        opacity: 0;
        visibility: hidden;
        transition: opacity 1.2s, visibility 0s linear 1.2s;

        &.visible {
            opacity: 1;
            transition: opacity 1.2s, visibility 0s;
            visibility: visible;
        }
    }
}



